import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Accordion } from "./index";
import { BulbIcon, HeadIcon, MagnifierIcon, PersonIcon, RadarIcon } from "../../assets/icons";
import { iconsMappingList } from "../../util/workshop.util";
import { Symbol } from "../../pages/summary";

import mapStatImg0 from "../../assets/images/insights/mapStats/0.png";
import mapStatImg1 from "../../assets/images/insights/mapStats/1.png";
import mapStatImg2 from "../../assets/images/insights/mapStats/2.png";
import mapStatImg3 from "../../assets/images/insights/mapStats/3.png";

import { FaCompress, FaExpand } from "react-icons/fa";

const iconsMap = {
  magnifier: MagnifierIcon,
  head: HeadIcon,
  person: PersonIcon,
  radar: RadarIcon
};

const mapStatsImagesMap = {
  0: mapStatImg0,
  1: mapStatImg1,
  2: mapStatImg2,
  3: mapStatImg3
};

const mapIconsLocationClasses = {
  0: "left-1 top-1",
  1: "right-1 top-1",
  2: "left-1 bottom-1",
  3: "right-1 bottom-1"
};

const TeamMaps = ({ mapNames, platform = "mt", isPdf = false, showStats = true }) => {
  const { t } = useTranslation();

  const translationPrefix = platform === "mft" ? "mft.maps" : "maps";
  const teamMaps = t(translationPrefix, { returnObjects: true });

  const heroTranslations = t("hero", { returnObjects: true });
  const { maps } = heroTranslations;

  const [mapsStats, setMapsStats] = useState([]);
  const [mapZoom, setMapZoom] = useState("");

  const mapStatsRef = useRef(null);
  const mapZoomRef = useRef(null);

  const content = () => {
    const { description = "", tips = [] } = teamMaps?.content || {};
    const { image } = teamMaps?.stats || {};

    return (
      <div className="block w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF] grid grid-cols-[auto_1fr] items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="hidden sm:block w-12 h-12 shrink-0">
            <BulbIcon />
          </span>
          <ul className={`list-disc pl-6 ${isPdf ? "text-xs" : ""}`}>
            {tips.map((tip, index) => (
              <li
                key={`${tip}-${index}`}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>

        <div className={`w-full mt-4 ${isPdf ? "bg-orange-200 py-4 px-28 rounded-2xl" : ""}`}>
          <div
            className={
              isPdf
                ? "flex items-center justify-center bg-orange-200 px-10 py-5 rounded-2xl"
                : `grid grid-cols-1 sm:grid-cols-1 gap-4 max-w-4xl mx-auto`
            }>
            <div className="w-full">
              <div
                ref={mapZoomRef}
                className={`rounded-2xl bg-white-100 border-2 border-gray-250/40 p-2 flex flex-col mx-auto overflow-auto no-scrollbar`}>
                <div
                  className="relative flex flex-wrap gap-0 overflow-hidden transition-all"
                  style={{
                    width: mapZoom ? "200%" : ""
                  }}>
                  <img
                    src={
                      "https://mission-future-docs.s3.eu-central-1.amazonaws.com/mission_team/Maps.png"
                    }
                    className="block w-full h-full rounded-xl"
                    alt="map"
                  />
                  <div className="grid grid-cols-2 grid-rows-2 w-full h-full absolute left-0 top-0">
                    {Array.from({ length: 4 }, (_, index) => (
                      <div
                        key={index}
                        className="relative w-full h-full flex items-center justify-center"
                        onClick={() => {
                          if (window.innerWidth > 700) return setMapZoom(false);
                          setMapZoom(!mapZoom);

                          setTimeout(() => {
                            if (index === 0 || index === 2) {
                              mapZoomRef.current.scrollTo({
                                left: 0,
                                behavior: "smooth"
                              });
                            } else {
                              mapZoomRef.current.scrollTo({
                                left: mapZoomRef.current.scrollWidth,
                                behavior: "smooth"
                              });
                            }
                          }, 100);
                        }}>
                        <p
                          className="font-righteous text-xl text-white-100 text-center"
                          dangerouslySetInnerHTML={{ __html: maps?.[index] }}
                        />
                        <button
                          className={`block sm:hidden absolute ${index === 0 || index === 2 ? "right-0 bottom-0" : "left-0 bottom-0"} p-2`}>
                          {!mapZoom && <FaExpand color="#454444" size={18} />}
                          {mapZoom && <FaCompress color="#454444" size={18} />}
                        </button>
                      </div>
                    ))}
                  </div>

                  {Array.from({ length: 4 }, (_, index) => (
                    <React.Fragment key={index}>
                      <div
                        className={`absolute ${mapIconsLocationClasses[index]} flex gap-1 ${index > 1 ? "flex-col-reverse" : "flex-col"} w-14 sm:w-20 z-10`}>
                        <div className={`flex gap-1 p-1.5 bg-white-100 rounded-full`}>
                          {iconsMappingList[index].map((icon, i) => {
                            const Icon = iconsMap[icon];
                            return (
                              <span
                                key={`${index}-${i}`}
                                className="w-5 h-5 sm:w-8 sm:h-8 bg-gray-200 rounded-full p-1/2 sm:p-2">
                                <Icon />
                              </span>
                            );
                          })}
                        </div>
                        <div
                          className={`flex flex-wrap ${(index + 1) % 2 !== 0 ? "justify-start" : "justify-end"}`}>
                          {mapNames?.[index] !== undefined && (
                            <div
                              className={`flex w-4/5 sm:w-full flex-wrap sm:p-1 ${(index + 1) % 2 !== 0 ? "justify-start" : "justify-end"}`}>
                              {mapNames[index].map(({ name, symbolIndex }, symIndex) => {
                                return (
                                  <div
                                    key={`${symIndex}-${symbolIndex}`}
                                    className="w-full sm:w-1/2">
                                    <Symbol name={name} symbolIndex={symbolIndex} />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isPdf && showStats && (
          <div className="mt-28 sm:mt-36">
            <div className="relative">
              <div className="absolute left-0 -top-20 sm:-top-32 w-full">
                <img src={image} alt="Lynn" className="w-full block mx-auto sm:max-w-xl" />
              </div>

              <div className="rounded-lg border-2 border-gray-250/40 bg-white-100 p-6 w-full relative mt-6">
                <div
                  ref={mapStatsRef}
                  className="w-full h-auto flex flex-wrap items-center justify-center gap-3">
                  {mapsStats.map((map, index) => {
                    const { id, value, color, stroke, mapInnerImage } = map;
                    let targetWidth = value;

                    targetWidth = value;

                    let flexPlacement = "items-end justify-end";
                    if (index === 1) flexPlacement = "items-end justify-start";
                    if (index === 2) flexPlacement = "items-start justify-end";
                    if (index === 3) flexPlacement = "items-start justify-start";

                    const maxWidthPx = mapStatsRef?.current?.clientWidth;
                    targetWidth = targetWidth / 100;
                    targetWidth = targetWidth * maxWidthPx;
                    targetWidth = Math.sqrt(targetWidth / Math.PI) * 40;

                    return (
                      <div key={value + index} className={`flex ${flexPlacement} p-[8px]`}>
                        <div
                          className="relative"
                          style={{
                            width: `${targetWidth}px`,
                            height: `${targetWidth}px`
                          }}>
                          <div
                            className="w-full h-full flex items-center justify-center"
                            style={{
                              borderRadius: "50%",
                              background: `linear-gradient(127deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, ${stroke} 35%, ${stroke} 100%)`
                            }}>
                            <div
                              className="flex items-center justify-center"
                              style={{
                                width: "calc(100% - 6px)",
                                height: "calc(100% - 6px)",
                                borderRadius: "50%",
                                background: `white`
                              }}>
                              <div
                                key={index}
                                className="relative flex items-center justify-center gap-2 flex-col"
                                style={{
                                  width: "calc(100% - 8px)",
                                  height: "calc(100% - 8px)",
                                  borderRadius: "50%",
                                  backgroundColor: color
                                }}>
                                <p className="text-[120%] font-righteous" style={{ color: stroke }}>
                                  {value}%
                                </p>
                                <p
                                  className="text-[100%] font-righteous"
                                  style={{
                                    color: stroke
                                  }}>
                                  {id}
                                </p>
                                <img
                                  src={mapInnerImage}
                                  alt="map"
                                  className="w-1/2 h-auto max-h-1/4"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (isPdf || !mapStatsRef?.current) return;

    try {
      // Calculate total submissions and percentage of submissions per map index
      let totalSubmissions = 0;
      const submissionsPerIndex = {
        0: 0,
        1: 0,
        2: 0,
        3: 0
      };

      Object.keys(mapNames).forEach((key) => {
        totalSubmissions += mapNames[key].length;
        submissionsPerIndex[key] = mapNames[key].length;
      });

      const finalData = [
        {
          id: maps?.[0],
          value: parseFloat(((submissionsPerIndex[0] / totalSubmissions) * 100).toFixed(2)),
          color: "rgb(246, 240, 252, 0.7)",
          stroke: "#B083E3",
          mapInnerImage: mapStatsImagesMap[0]
        },
        {
          id: maps?.[1],
          value: parseFloat(((submissionsPerIndex[1] / totalSubmissions) * 100).toFixed(2)),
          color: "rgb(255, 237, 209, 0.7)",
          stroke: "#F19202",
          mapInnerImage: mapStatsImagesMap[1]
        },
        {
          id: maps?.[2],
          value: parseFloat(((submissionsPerIndex[2] / totalSubmissions) * 100).toFixed(2)),
          color: "rgb(239, 247, 255, 0.7)",
          stroke: "#67AEF4",
          mapInnerImage: mapStatsImagesMap[2]
        },
        {
          id: maps?.[3],
          value: parseFloat(((submissionsPerIndex[3] / totalSubmissions) * 100).toFixed(2)),
          color: "rgb(230, 249, 236, 0.7)",
          stroke: "#2ABF5B",
          mapInnerImage: mapStatsImagesMap[3]
        }
      ].filter((d) => d.value > 0);

      setMapsStats(finalData);
    } catch (error) {
      console.log("Error while rendering bubble cloud", error);
    }
  }, [mapNames, mapStatsRef]);

  return (
    <div
      id="maps"
      className={
        isPdf ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden" : "mb-12"
      }>
      <Accordion title={teamMaps?.title} icon={teamMaps?.icon} content={content()} isPdf={isPdf} />
    </div>
  );
};

export default TeamMaps;
