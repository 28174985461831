import React, { useContext, useEffect } from "react";

import ReactGA from "react-ga4";

import { WorkshopContext } from "../contexts/workshop.context";
import {
  Header,
  Hero,
  TeamCommunicationPreferences,
  TeamRoles,
  TeamStrengths,
  Footer,
  TeamSkills,
  SuccessPillars,
  TeamMaps
} from "../components/insights";
import { ScrollToTopButton } from "../components/common";

const Insights = ({ demoWorkshop }) => {
  const {
    socket,
    workshopDetails: {
      id,
      teamName = "[Team Name]",
      roomData,
      pdf,
      platform,
      workshopDateString
    } = {}
  } = useContext(WorkshopContext);

  useEffect(() => {
    if (!demoWorkshop) return;

    ReactGA.initialize("G-SED12RVZLJ");
  }, [demoWorkshop]);

  return (
    <section
      id="insights"
      className={`font-inter mx-auto ${pdf ? "bg-white-100" : ""}`}
      style={{
        maxWidth: !pdf && "1100px"
      }}>
      {!pdf && <Header id={id} socket={socket} platform={platform} />}
      {platform === "mft" ? (
        <>
          {!pdf && (
            <Hero
              teamName={teamName}
              workshopDate={workshopDateString}
              id={id}
              platform={platform}
              socket={socket}
            />
          )}
          <TeamMaps mapNames={roomData?.task4} platform={platform} isPdf={pdf} showStats={false} />
          <TeamStrengths
            strengths={roomData?.strengths}
            users={roomData?.users}
            strengthsSubmissions={roomData?.strengthsSubmissions}
            isPdf={pdf}
            platform={platform}
            showStats={false}
          />
          <TeamRoles
            roles={roomData?.roles}
            users={roomData?.users}
            rolesSubmissions={roomData?.rolesSubmissions}
            isPdf={pdf}
            platform={platform}
            showStats={false}
          />
          <TeamCommunicationPreferences
            starSelections={roomData?.task8}
            attitudesSelections={roomData?.task9}
            users={roomData?.users}
            isPdf={pdf}
            platform={platform}
            showStats={false}
          />
          {!pdf && <Footer platform={platform} />}
        </>
      ) : (
        <>
          {!pdf && (
            <Hero
              teamName={teamName}
              workshopDate={workshopDateString}
              id={id}
              socket={socket}
              demoWorkshop={demoWorkshop}
            />
          )}
          <TeamMaps mapNames={roomData?.task4} platform={platform} isPdf={pdf} />
          <TeamStrengths
            strengths={roomData?.strengths}
            users={roomData?.users}
            strengthsSubmissions={roomData?.strengthsSubmissions}
            isPdf={pdf}
            platform={platform}
          />
          <TeamRoles
            roles={roomData?.roles}
            users={roomData?.users}
            rolesSubmissions={roomData?.rolesSubmissions}
            isPdf={pdf}
            platform={platform}
          />
          <TeamCommunicationPreferences
            starSelections={roomData?.task8}
            attitudesSelections={roomData?.task9}
            users={roomData?.users}
            isPdf={pdf}
            platform={platform}
          />
          <TeamSkills
            users={roomData?.users}
            skillsSubmissions={roomData?.task10?.skillsSubmissions}
            isPdf={pdf}
          />
          <SuccessPillars
            users={roomData?.users}
            successPillarsSubmissions={roomData?.task10?.successPillarsSubmissions}
            isPdf={pdf}
          />
          {!pdf && <Footer platform={platform} />}
        </>
      )}

      <ScrollToTopButton />
    </section>
  );
};

export default Insights;
