import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, DownloadPdf } from ".";
import { BulbIcon, CopyIconHollow, ExternalLinkIconHollow } from "../../assets/icons";
import { FaCheck, FaCopy } from "react-icons/fa";
import { defaultPlatforms } from "../../util/workshop.util";

const DemoOption = ({ text, buttonText, href }) => (
  <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-0 bg-white rounded-xl p-2 md:px-6">
    <span
      className="w-full sm:flex-1 h-[45px] bg-white-100 flex items-center px-4 md:px-6 rounded-full border border-gray-250/30 text-gray-250 text-left sm:mr-6 text-base sm:text-[18px]"
      dangerouslySetInnerHTML={{ __html: text }}
    />
    <a
      href={href}
      className="text-center bg-orange-100 text-white-100 h-[45px] rounded-full hover:bg-orange-200 transition-colors whitespace-nowrap w-full sm:w-[200px] font-medium text-base sm:text-[18px] flex items-center justify-center"
      dangerouslySetInnerHTML={{ __html: buttonText }}
    />
  </div>
);

const DemoHeader = () => {
  const { t } = useTranslation();
  let buttonOptions = t("mtDemo.hero.header.buttonOptions", { returnObjects: true });

  if (!Array.isArray(buttonOptions)) buttonOptions = [];

  return (
    <div className="my-12 text-center bg-gray-50 p-4 sm:p-6 md:p-8 rounded-lg border border-gray-250/30">
      <h1
        className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 md:mb-6"
        dangerouslySetInnerHTML={{ __html: t("mtDemo.hero.header.title") }}
      />
      <p
        className="text-base sm:text-lg md:text-xl mb-6 md:mb-10 max-w-3xl mx-auto"
        dangerouslySetInnerHTML={{ __html: t("mtDemo.hero.header.description") }}
      />
      <div className="flex flex-col gap-2 md:gap-2 max-w-4xl mx-auto">
        {buttonOptions
          .filter((option) => option.text !== "hideme")
          .map((option, index) => (
            <DemoOption
              key={index}
              text={option.text}
              buttonText={option.buttonText}
              href={option.href}
            />
          ))}
      </div>
    </div>
  );
};

const InsightsHeroSection = ({
  teamName,
  id,
  platform = "mt",
  workshopDate,
  socket,
  demoWorkshop
}) => {
  const { t } = useTranslation();

  const translationPrefix = demoWorkshop ? "mtDemo." : "";
  const heroTranslations =
    platform === "mt"
      ? t(`${translationPrefix}hero`, { returnObjects: true })
      : t("mft.hero", { returnObjects: true });

  const { initialTitle, reflections, overviewImage, downloadSection } = heroTranslations || {};

  const [copied, setCopied] = useState(false);

  const renderDemoHeader = () => {
    if (!demoWorkshop) return null;

    return <DemoHeader />;
  };

  const content = () => {
    const { description = "", tips = [] } = reflections?.content || {};
    return (
      <div id="reflections" className="block w-full">
        <p className="mb-4 text-gray-250" dangerouslySetInnerHTML={{ __html: description }} />

        <div
          className={
            "rounded-lg border-2 border-gray-250/40 bg-[#FFF] grid grid-cols-[auto_1fr] items-center gap-3 p-4"
          }>
          <span className="hidden sm:block w-12 h-12 shrink-0">
            <BulbIcon />
          </span>
          <ul className="list-disc pl-6">
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const downloadSectionContent = () => {
    const {
      description = "",
      copyText = "",
      downloadText = "",
      externalLinkText = "",
      externalLInkHref = "#"
    } = downloadSection?.content || {};
    return (
      <div id="deepInsights" className="block w-full">
        <p className="mb-4 text-gray-250" dangerouslySetInnerHTML={{ __html: description }} />
        <div className="flex gap-2 items-center justify-center flex-col w-full">
          <div className="w-full flex gap-1 items-center justify-between h-12">
            <div
              className="border-2 border-gray-250/30 bg-orange-700 rounded-lg p-2 text-gray-250 text-sm font-medium w-full h-full flex items-center"
              dangerouslySetInnerHTML={{ __html: copyText }}
            />
            <div
              className="bg-orange-100 rounded-md p-2 text-white-100 text-base font-medium w-[10%] sm:w-[7%] h-full cursor-pointer flex items-center justify-center"
              onClick={() => {
                try {
                  navigator.clipboard.writeText(`${window.location.origin}/insights/${id}`);
                  setCopied(true);
                } catch (error) {
                  console.log("Error while copying", error);
                }
              }}>
              {!copied && (
                <span className="w-8 h-8">
                  <CopyIconHollow />
                </span>
              )}
              {copied && <FaCheck />}
            </div>
          </div>
          <div className="w-full flex gap-1 items-center justify-between h-12">
            <div
              className="border-2 border-gray-250/30 bg-orange-700 rounded-lg p-2 text-gray-250 text-sm font-medium w-full h-full flex items-center"
              dangerouslySetInnerHTML={{ __html: downloadText }}
            />
            <div className="bg-orange-100 rounded-md p-2 text-white-100 text-base font-medium w-[10%] sm:w-[7%] h-full cursor-pointer flex items-center justify-center">
              <DownloadPdf
                workshopId={id}
                downloadPDFTitle={`${teamName}-${workshopDate}`}
                socket={socket}
                buttonType="icon"
                className="max-w-full w-full"
              />
            </div>
          </div>
          {externalLinkText && (
            <div className="w-full flex gap-1 items-center justify-between h-12">
              <div
                className="border-2 border-gray-250/30 bg-orange-700 rounded-lg p-2 text-gray-250 text-sm font-medium w-full h-full flex items-center"
                dangerouslySetInnerHTML={{ __html: externalLinkText }}
              />
              <a
                href={externalLInkHref}
                target="_blank"
                rel="noreferrer"
                className="w-[10%] sm:w-[7%] h-full">
                <div className="bg-orange-100 rounded-md p-2 text-white-100 text-base font-medium w-full h-full cursor-pointer flex items-center justify-center">
                  <span className="w-8 h-8">
                    <ExternalLinkIconHollow />
                  </span>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  };

  const mftDownloadSectionContent = () => {
    const { description = "", copyText = "", downloadText = "" } = downloadSection?.content || {};
    return (
      <div id="deepInsights" className="block w-full">
        <p className="mb-4 text-gray-250" dangerouslySetInnerHTML={{ __html: description }} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center justify-center">
          <div className="w-full flex gap-1 items-center justify-between h-12">
            <div
              className="border-2 border-gray-250/30 rounded-lg p-2 bg-white-100 text-gray-250 text-sm font-medium w-full h-full flex items-center"
              dangerouslySetInnerHTML={{ __html: copyText }}
            />
            <div
              className="bg-orange-100 rounded-md p-2 text-white-100 text-base font-medium w-[14%] sm:w-[10%] h-full cursor-pointer"
              onClick={() => {
                try {
                  navigator.clipboard.writeText(`${window.location.origin}/insights/${id}`);
                  setCopied(true);
                } catch (error) {
                  console.log("Error while copying", error);
                }
              }}>
              {!copied && <FaCopy />}
              {copied && <FaCheck />}
            </div>
          </div>
          <div className="w-full flex gap-1 items-center justify-between h-12">
            <div
              className="border-2 border-gray-250/30 rounded-lg p-2 bg-white-100 text-gray-250 text-sm font-medium w-full h-full flex items-center"
              dangerouslySetInnerHTML={{ __html: downloadText }}
            />
            <div className="bg-orange-100 rounded-md p-2 text-white-100 text-base font-medium w-[14%] sm:w-[10%] h-full cursor-pointer">
              <DownloadPdf
                workshopId={id}
                downloadPDFTitle={`${teamName}-${workshopDate}`}
                socket={socket}
                buttonType="icon"
                className="max-w-full w-full"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="hero" className={"mb-12"}>
      <h2 className="text-gray-250 text-3xl font-extrabold text-center">
        <span className="uppercase">{teamName}</span>
        <span
          className="ml-2 inline-block text-3xl font-normal font-gochi text-orange-100"
          dangerouslySetInnerHTML={{ __html: initialTitle }}
        />
      </h2>

      {renderDemoHeader()}
      <>
        <img src={overviewImage} className="w-full max-w-2xl block my-6 mx-auto" alt="hero" />
        <div className="my-12">
          <Accordion
            title={reflections?.title}
            icon={reflections?.icon}
            content={content()}
            isStatic={true}
            defaultOpen={true}
          />
        </div>
        {!demoWorkshop && (
          <div className="my-12">
            <Accordion
              title={downloadSection?.title}
              // icon={downloadSection?.icon}
              content={
                platform === defaultPlatforms.mft
                  ? mftDownloadSectionContent()
                  : downloadSectionContent()
              }
              isStatic={true}
              defaultOpen={true}
              backgroundColor={"#ffffff"}
              titleColor={"#F39200"}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default InsightsHeroSection;
