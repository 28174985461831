import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { BulbIcon, TrophyIcon } from "../../assets/icons";
import { Accordion, WordCloudRenderer } from "./index";
import { getInitials } from "../../util/general.util";
import { parseWordsList, skillsPillasCloudColors } from "../../util/wordcloud.util";

const SuccessPillars = ({ successPillarsSubmissions = {}, users = {}, isPdf = false }) => {
  const { t } = useTranslation();

  const teamSuccessPillarsAndSkills = t("successPillars", { returnObjects: true });
  const successPillars = t("resources.successPillars", { returnObjects: true });

  const [validPillars, setValidPillars] = useState([]);
  const [successPillarWords, setSuccessPillarWords] = useState([]);

  const getPillarUsers = (pillar) => {
    return Object.entries(successPillarsSubmissions)
      .filter(([_, value]) => value?.toLowerCase()?.trim() === pillar?.toLowerCase()?.trim())
      .map(([userId]) => {
        const user = users.find((u) => u.id.toString() === userId);
        return {
          id: userId,
          name: user ? user.name : userId
        };
      });
  };

  const getAllValidPillarsWithUsersSubmissions = () => {
    const allPillars = successPillars.map((pillar) => {
      const pillarUsers = getPillarUsers(pillar);
      return {
        pillar,
        users: pillarUsers
      };
    });

    return allPillars.filter((pillar) => pillar.users.length > 0);
  };

  const content = (part = "first") => {
    const firstFourPillars = [...validPillars]?.splice(0, 4);
    const remainingPillars = [...validPillars]?.splice(4);

    const targetPillars = isPdf
      ? part === "first"
        ? firstFourPillars
        : remainingPillars
      : validPillars;

    const { description, tips = [] } = teamSuccessPillarsAndSkills?.content || {};
    return (
      <div id="successPillars" className="w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        {part === "first" && (
          <div
            className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF] grid grid-cols-[auto_1fr] items-center gap-3 ${
              isPdf ? "px-4 py-2" : "p-4"
            }`}>
            <span className="hidden sm:block w-12 h-12 shrink-0">
              <BulbIcon />
            </span>
            <ul className={`list-disc pl-6 ${isPdf ? "text-xs" : ""}`}>
              {tips.map((tip, index) => (
                <li
                  key={index}
                  className="text-[#575756]"
                  dangerouslySetInnerHTML={{ __html: tip }}
                />
              ))}
            </ul>
          </div>
        )}

        {!isPdf && successPillarWords?.length > 0 && (
          <div className={"w-full mt-4"}>
            <WordCloudRenderer
              id={"successPillars"}
              words={successPillarWords}
              colorFunction={() => skillsPillasCloudColors.successPillarsWords}
              weightFactorFormula={(size) => size * 1.5}
              width={1500}
              height={1500}
            />
          </div>
        )}

        <div className={`w-full mt-4 ${isPdf ? "bg-orange-200 py-4 px-2 rounded-2xl" : ""}`}>
          <div
            className={`w-full md:max-w-4xl mx-auto ${
              isPdf ? "grid grid-cols-1 gap-4 !max-w-full" : "flex flex-col"
            }`}>
            {targetPillars?.map((pillar, index) => {
              const { pillar: pillarTitle, users: pillarUsers } = pillar;
              if (pillarUsers.length === 0) return null;

              return (
                <div key={index} className={`${!isPdf && "mb-4"} last:mb-0`}>
                  <div className="bg-white-100 rounded-lg p-2 border border-gray-250/40">
                    <div className="flex items-center gap-2 mb-2">
                      <span className="w-5 h-5 block">
                        <TrophyIcon />
                      </span>
                      <p
                        className="font-medium text-green-200 font-gochi"
                        dangerouslySetInnerHTML={{ __html: pillarTitle }}
                      />
                    </div>
                    <div className="flex flex-wrap gap-4">
                      {pillarUsers.map((user) => (
                        <UserSkillTag key={user.id} user={user} />
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const validPillars = getAllValidPillarsWithUsersSubmissions();
    setValidPillars(validPillars);

    try {
      const parsedSuccessPillarWords = parseWordsList(successPillarsSubmissions, "successPillars");
      if (parsedSuccessPillarWords?.length > 0) {
        setTimeout(() => {
          setSuccessPillarWords(parsedSuccessPillarWords);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  }, [successPillarsSubmissions]);

  return (
    <>
      <div
        id="successPillars"
        className={
          isPdf ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden" : "mb-12"
        }>
        <Accordion
          title={teamSuccessPillarsAndSkills?.title}
          icon={teamSuccessPillarsAndSkills?.icon}
          content={content("first")}
          isPdf={isPdf}
        />
      </div>

      {isPdf && validPillars?.length > 4 && (
        <div
          id="successPillars"
          className={
            isPdf
              ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden"
              : "mb-12"
          }>
          <Accordion
            title={teamSuccessPillarsAndSkills?.title + ` (${t("successPillars.continued")})`}
            icon={teamSuccessPillarsAndSkills?.icon}
            content={content("second")}
            isPdf={isPdf}
          />
        </div>
      )}
    </>
  );
};

const UserSkillTag = ({ user }) => {
  const initials = getInitials(user.name);
  return (
    <div className="w-8 h-8 bg-orange-100 rounded-full flex items-center justify-center text-xs font-bold text-white-100 flex-shrink-0">
      {initials}
    </div>
  );
};

export default SuccessPillars;
