import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";
import { Button, ScenarioBox } from "../components/workshop";

import logo from "../assets/images/logo.png";
import lynnImage from "../assets/images/lynn-to-right.png";
import { defaultRoles } from "../util/workshop.util";

const PreSummary = ({ demoWorkshop = false }) => {
  const { t, i18n } = useTranslation();

  const { user: { role } = {}, workshopDetails: { _id, teamName } = {} } =
    useContext(WorkshopContext);

  // If someone is landing here without being part of workshop
  useEffect(() => {
    if (_id && teamName && !role) {
      window.location.href = demoWorkshop ? "/insights/demo" : `/insights/${_id}`;
    }
  }, [_id, teamName]);

  return (
    <section id="presummary" className="relative max-w-2xl mx-auto flex flex-col gap-4">
      <img src={lynnImage} alt="lynn" className="w-60 mx-auto absolute -left-10 -top-14 z-10" />

      <div className={`relative z-20 ${demoWorkshop ? "flex flex-col gap-12" : ""}`}>
        <img src={logo} alt="logo" className="w-24 mx-auto mt-10" />
        {!demoWorkshop && (
          <>
            <h3
              className="font-medium text-orange-100 text-center font-righteous text-3xl"
              dangerouslySetInnerHTML={{ __html: teamName }}
            />

            {role === defaultRoles.participant && (
              <>
                <h1
                  dangerouslySetInnerHTML={{ __html: t("preSummary.heading") }}
                  className="text-5xl font-medium font-righteous text-gray-250 mt-4"
                />
                <p
                  dangerouslySetInnerHTML={{ __html: t("preSummary.subheading") }}
                  className="text-xl text-gray-250 font-medium"
                />
                <p
                  dangerouslySetInnerHTML={{ __html: t("preSummary.description") }}
                  className="text-xl text-gray-100 mb-4"
                />
              </>
            )}

            {role === defaultRoles.moderatorAndParticipant && (
              <>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t("preSummary.heading1")
                  }}
                  className="text-5xl font-medium font-righteous text-gray-250 mt-4"
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("preSummary.subheading1")
                  }}
                  className="text-xl text-gray-250 font-medium"
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("preSummary.description1")
                  }}
                  className="text-xl text-gray-100 mb-4"
                />
              </>
            )}

            {role === defaultRoles.moderator && (
              <>
                <h1
                  dangerouslySetInnerHTML={{ __html: t("preSummary.heading2") }}
                  className="text-5xl font-medium font-righteous text-gray-250 mt-4"
                />
                <p
                  dangerouslySetInnerHTML={{ __html: t("preSummary.subheading2") }}
                  className="text-xl text-gray-250 font-medium"
                />
                <p
                  dangerouslySetInnerHTML={{ __html: t("preSummary.description2") }}
                  className="text-xl text-gray-100 mb-4"
                />
              </>
            )}
          </>
        )}

        {demoWorkshop && (
          <>
            <h1
              dangerouslySetInnerHTML={{
                __html: t("preSummary.heading")
              }}
              className="text-5xl font-medium font-righteous text-gray-250 mt-20"
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("preSummary.description")
              }}
              className="text-xl text-gray-100 mb-4"
            />
            <ScenarioBox text={t("preSummary.scenario")} />
          </>
        )}

        <Button
          text={t("preSummary.button")}
          onClick={() => {
            if (demoWorkshop) {
              const currentLang = i18n.language;
              const demoUrl = `${window.location.origin}/insights/demo?lang=${currentLang}`;
              // Only send postMessage if we're in an iframe
              if (window !== window.parent) {
                window.parent.postMessage({ type: "NAVIGATE", url: demoUrl }, "*");
              } else {
                window.location.href = demoUrl;
              }
            } else {
              window.location.href = `/insights/${_id}`;
            }
          }}
        />
      </div>
    </section>
  );
};

export default PreSummary;
