import React from "react";

const ScenarioBox = ({ text = "Scenario" }) => {
  return (
    <div className="bg-white-100 border-[0.5px] border-gray-250 rounded-lg p-3 sm:p-4 my-3 sm:my-4">
      <p
        className="text-gray-500 text-sm sm:text-base"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default ScenarioBox;
