import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WorkshopContext } from "../contexts/workshop.context";

import {
  Button,
  EmojiSender,
  ParticipantJoinQR,
  ParticipantsList,
  Popup,
  QRPopup,
  ScenarioBox,
  ScreenBottom,
  VerticalSpace
} from "../components/workshop";

import { defaultPlatforms, defaultRoles } from "../util/workshop.util";

import logo from "../assets/images/logo.png";
import lynnImage from "../assets/images/lynn-to-right.png";

const Wait = ({ demoWorkshop = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { platform } = useContext(WorkshopContext);

  const [removeUserId, setRemoveUserId] = useState(null);

  const handleStartWorkshop = () => {
    if (demoWorkshop) {
      navigate("/demo/video/1");
    } else {
      socket.emit("startWorkshop");
    }
  };

  const {
    socket,
    user: { id, role } = {},
    workshopUsers,
    allUsersWithMods,
    workshopDetails
  } = useContext(WorkshopContext);

  return (
    <div className="flex flex-col gap-4">
      {demoWorkshop ? (
        <div className="relative">
          <img src={lynnImage} alt="lynn" className="w-60 mx-auto absolute -left-10 -top-14 z-10" />
          <div className="relative z-20">
            <img src={logo} alt="logo" className="w-24 mx-auto mb-20 mt-10" />
            <h1 className="font-medium text-gray-250 text-center font-righteous text-3xl">
              {t("waitingScreen.heading")}
            </h1>
            <ScenarioBox text={t("waitingScreen.scenario")} />
            <p
              className="text-xl text-gray-250 my-4"
              dangerouslySetInnerHTML={{ __html: t("waitingScreen.actionHelp") }}
            />
          </div>
        </div>
      ) : (
        <>
          {role === defaultRoles.participant && (
            <h1
              dangerouslySetInnerHTML={{ __html: t("mtParticipantWaitingRoom.heading") }}
              className="text-5xl font-bold font-righteous text-gray-250"
            />
          )}
          {role === defaultRoles.moderator && (
            <h1
              dangerouslySetInnerHTML={{ __html: t("mtModeratorwaitingRoom.heading") }}
              className="text-5xl font-bold font-righteous text-gray-250"
            />
          )}
          {role === defaultRoles.moderatorAndParticipant && (
            <h1
              dangerouslySetInnerHTML={{ __html: t("mtModeratorParticipantwaitingRoom.heading") }}
              className="text-5xl font-bold font-righteous text-gray-250"
            />
          )}
          {role === defaultRoles.participant && (
            <p
              dangerouslySetInnerHTML={{ __html: t("mtParticipantWaitingRoom.subheading") }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderator && (
            <p
              dangerouslySetInnerHTML={{ __html: t("mtModeratorwaitingRoom.subheading") }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderatorAndParticipant && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtModeratorParticipantwaitingRoom.subheading")
              }}
              className="text-xl text-gray-100"
            />
          )}
        </>
      )}

      {role !== defaultRoles.participant && platform !== defaultPlatforms.mtDemo && (
        <>
          <VerticalSpace />
          <ParticipantJoinQR />
        </>
      )}

      {!demoWorkshop && <VerticalSpace />}

      <div className="relative">
        <div className="absolute right-0 -top-3 z-50">
          <EmojiSender socket={socket} usersList={allUsersWithMods} currentUserId={id} />
        </div>
        <ParticipantsList
          heading={`${workshopUsers?.length} ${t("mtParticipantWaitingRoom.listHeading")}`}
          participants={workshopUsers}
          verticalListOnly={true}
          moderator={role !== defaultRoles.participant}
          removeUser={
            demoWorkshop
              ? undefined
              : (id) => {
                  setRemoveUserId(id);
                }
          }
          userOwnId={id}
        />
      </div>

      <Popup open={removeUserId} setOpen={setRemoveUserId} autoHeight={true}>
        <p
          className="text-center font-bold text-2xl text-black-100"
          dangerouslySetInnerHTML={{ __html: t("removeParticipant.heading") }}
        />

        <div className="w-full flex gap-4 mt-5">
          <span className="w-1/2">
            <Button
              text={t("removeParticipant.cancel")}
              onClick={() => setRemoveUserId(null)}
              type="secondary"
            />
          </span>
          <span className="w-1/2">
            <Button
              text={t("removeParticipant.confirm")}
              onClick={() => {
                if (removeUserId === id) return;

                socket.emit("removeUser", removeUserId);
                setRemoveUserId(null);
              }}
            />
          </span>
        </div>
      </Popup>

      <ScreenBottom extraClasses={"flex-wrap"}>
        {role !== defaultRoles.participant && platform !== defaultPlatforms.mtDemo && (
          <div className="w-full">
            <QRPopup teamName={workshopDetails?.teamName} floating={false} />
          </div>
        )}
        {/* <Button
          text={t("mtParticipantWaitingRoom.sync")}
          type={"outline"}
          onClick={() =>
            socket.emit("syncToWorkshop", "", (url) => {
              navigate(url);
            })
          }
        /> */}
        {role !== defaultRoles.participant && (
          <Button text={t("mtModeratorwaitingRoom.button")} onClick={handleStartWorkshop} />
        )}
      </ScreenBottom>
    </div>
  );
};

export default Wait;
