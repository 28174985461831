import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import { ScenarioBox, SpeakingOrder, StarPicker } from "../components/workshop";
import { defaultRoles } from "../util/workshop.util";

const Task8b = ({ type, demoWorkshop }) => {
  const [taskOrderUsers, setTaskOrderUsers] = useState([]);

  const {
    socket,
    user: { id, name, role } = {},
    workshopDetails: { platform } = {},
    workshopUsers
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (demoWorkshop) {
      setTaskOrderUsers(workshopUsers);
      return;
    }
    socket?.emit("taskOrder", 4, (users) => {
      if (users) setTaskOrderUsers(users);
    });
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <h1
        dangerouslySetInnerHTML={{
          __html: type === "a" ? t("mtActivityEight.headingA") : t("mtActivityEight.headingB")
        }}
        className="text-5xl font-medium font-righteous text-gray-250"
      />

      {role === defaultRoles.participant && (
        <p
          dangerouslySetInnerHTML={{
            __html:
              type === "a" ? t("mtActivityEight.subheadingA") : t("mtActivityEight.subheadingB")
          }}
          className="text-xl text-gray-100"
        />
      )}
      {role === defaultRoles.moderator && (
        <p
          dangerouslySetInnerHTML={{
            __html:
              type === "a"
                ? t("mtActivityEight.subheadingAMod")
                : t("mtActivityEight.subheadingBMod")
          }}
          className="text-xl text-gray-100"
        />
      )}
      {role === defaultRoles.moderatorAndParticipant && (
        <p
          dangerouslySetInnerHTML={{
            __html:
              type === "a"
                ? t("mtActivityEight.subheadingAModPart")
                : t("mtActivityEight.subheadingBModPart")
          }}
          className="text-xl text-gray-100"
        />
      )}

      {demoWorkshop && <ScenarioBox text={t("task2b.scenario")} />}

      {!demoWorkshop && <SpeakingOrder participants={taskOrderUsers} />}

      <StarPicker
        socket={socket}
        id={"task8"}
        updateId={"updateTask8"}
        type={type}
        name={name}
        userId={id}
        role={role}
        platform={platform}
        demoWorkshop={demoWorkshop}
      />
    </div>
  );
};

export default Task8b;
