import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const StatsChart = ({ data, halfCol = false }) => {
  const { i18n } = useTranslation();

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    if (data) {
      const sortedData = data.sort((a, b) => {
        const totalAValue = (a?.value || 0) + (a?.value2 || 0);
        const totalBValue = (b?.value || 0) + (b?.value2 || 0);

        if (totalBValue === totalAValue) {
          return a.text.localeCompare(b.text, i18n.language);
        }
        return totalBValue - totalAValue;
      });
      setSortedData(sortedData);
    }
  }, [data, i18n.language]);

  return (
    <div className="w-full">
      {sortedData.map((item, index) => {
        const highestValue = (sortedData?.[0]?.value || 0) + (sortedData?.[0]?.value2 || 0);
        const currentValue = (item.value || 0) + (item?.value2 || 0);

        const percentage = ((currentValue / highestValue) * 100).toFixed(2);

        const partialAPercentage = item?.value ? ((item.value / currentValue) * 100).toFixed(2) : 0;
        const partialBPercentage = item?.value2
          ? ((item.value2 / currentValue) * 100).toFixed(2)
          : 0;

        return (
          <div key={index} className="grid grid-cols-12 gap-1 my-2">
            <div
              className={`${!halfCol ? "col-span-12 sm:col-span-3" : "col-span-12 sm:col-span-6"} text-gray-250 text-base font-medium"`}
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
            <div
              className={`${!halfCol ? "col-span-12 sm:col-span-9" : "col-span-12 sm:col-span-6 sm:pl-2"} pr-6`}>
              <span
                className="flex"
                style={{
                  width: `${percentage}%`
                }}>
                {partialAPercentage > 0 && (
                  <span
                    className="flex items-center justify-end pr-2 py-1/2 text-white-100 font-medium text-sm rounded"
                    style={{
                      width: `${partialAPercentage}%`,
                      backgroundColor: item?.color || "#E59536",
                      color: item?.textColor || "#FFF",
                      borderRadius: partialBPercentage > 0 ? "0.25rem 0 0 0.25rem" : "0.25rem"
                    }}>
                    {item.value}
                  </span>
                )}
                {partialBPercentage > 0 && (
                  <span
                    className="flex items-center justify-end pr-2 py-1/2 text-white-100 font-medium text-sm rounded"
                    style={{
                      width: `${partialBPercentage}%`,
                      backgroundColor: item?.color2 || "#E59536",
                      color: item?.textColor2 || "#FFF",
                      borderRadius: partialAPercentage > 0 ? "0 0.25rem 0.25rem 0" : "0.25rem"
                    }}>
                    {item.value2}
                  </span>
                )}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatsChart;
